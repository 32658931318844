import React from 'react';
import SgLayout from '~layouts/sg-layout';

const SgTypographyDisplay = () => {
    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Typography - Display styles</h1>
                    <h3>Examples</h3>
                </div>
            </section>
            <section className="project-example eg-typopgrahy-styles">
                <div className="container">
                    <div className="eg-display-styles">
                        <span className="display-xxl">Extra Extra Large (xxl)</span>
                        <span className="display-xl">Extra Large (xl)</span>
                        <span className="display-lg">Large (lg)</span>
                        <span className="display-md">Medium (md)</span>
                        <span className="display-sm">Small (sm)</span>
                        <span className="display-xs">Extra Small (xs)</span>
                    </div>
                    <div className="eg-display-styles">
                        <h1>HEADING 1 - XXL</h1>
                        <h2>HEADING 2 - XL</h2>
                        <h3>HEADING 3 - LG</h3>
                        <h4>HEADING 4 - M</h4>
                        <h5>HEADING 5 - SM</h5>
                        <h6>HEADING 6 - XS</h6>
                    </div>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>How to use</h2>
                    <p>Add <code>@include display-SIZE;</code> to any SCSS file. <code>H1</code> to <code>H6</code> all have default sizes, but can be overriden by the mixin. This keeps the HTML semantics separate from the visual hierarchy.</p>
                    <h3>Size options</h3>
                    <ol>
                        <li>xxl</li>
                        <li>xl</li>
                        <li>lg</li>
                        <li>m</li>
                        <li>sm</li>
                        <li>xs</li>
                    </ol>
                </div>
            </section>
        </SgLayout>
    );
};

export default SgTypographyDisplay;
